<template>
<div class="auth-login-page">
  <a-row class="login-content" align="middle">
    <!-- Sign In Form Column -->
    <a-col :span="24">
      <div class="content-title">合作社管理平台</div>
      <div class="content-subtitle">請輸入帳號及密碼進行登入</div>

      <!-- Sign In Form -->
      <a-form
        :model="formState"
        layout="vertical"
        autocomplete="off"
      >
        <a-form-item
          label="帳號"
          name="identifier"
          :disable="loading.status"
          :rules="[{ required: true, message: 'Please input your account.' }]"
        >
          <a-input v-model:value="formState.identifier" :disabled="loading.status" size="large" />
        </a-form-item>

        <a-form-item
          label="密碼"
          name="password"
          :rules="[{ required: true, message: 'Please input your password.' }]"
        >
          <a-input-password v-model:value="formState.password" :disabled="loading.status" size="large" />
        </a-form-item>

        <a-form-item :style="{marginTop: '40px'}">
          <a-button 
            type="primary"
            html-type="submit"
            :disabled="disabled"
            :loading="loading.status"
            size="large"
            :style="{width: '100%'}"
            @click="login"
          >登入</a-button>
        </a-form-item>
      </a-form>
      <!-- Sign In Form -->
    </a-col>
    <!-- Sign In Form Column -->
  </a-row>
</div>
</template>

<script>
import axios from 'axios'
import { defineComponent, reactive, computed } from 'vue'
import { message } from 'ant-design-vue'

export default defineComponent({
  components: {},
  setup() {
    const formState = reactive({
      identifier: '',
      password: ''
    });
    const loading = reactive({
      status: false
    });
    const disabled = computed(() => {
      return !(formState.identifier && formState.password);
    });

    return {
      formState,
      loading,
      disabled
    }
  },
  methods: {
    /* func */
    async login() {
      this.loading.status = true;
      const url = process.env.VUE_APP_API + '/auth/local';

      /* login */
      try {
        let response = await axios.post(url, this.formState);

        if (response.data.user.role.id != 5) {
          message.error('Login failed. The account is not authenticated.')
          return
        }
        console.log(response);
        this.$store.dispatch('auth/setAuth', {
          token: response.data.jwt,
          info: response.data.user
        });

        this.$router.push({ name: 'AppMembers' });
        let msg = `嗨 ${response.data.user.coop_info.name}，歡迎來到合作社管理平台`
        message.success(msg);
      } catch (error) {
        console.log(error);

        if (error.response.status == 400) {
          message.error('登入失敗，請檢查帳號密碼是否正確')
        } else {
          message.error('登入發生錯誤，請稍後再試')
        }
      } finally {
        this.loading.status = false;
      }
    }
  }
})
</script>

<style lang="scss">
.auth-login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;

  .login-content {
    width: 500px;
    margin: 0 auto;

    .content-title {
      font-size: 2.5rem;
      font-weight: bold;
    }

    .content-subtitle {
      color: #8C8C8C;
      font-size: 1.2rem;
      font-weight: 400;
      letter-spacing: 0.0325rem;
      margin-bottom: 30px;
    }
  }
}
</style>